import Vue from 'vue'
import VueRouter from 'vue-router'
// import projectManage from '../views/projectManage/index.vue'


Vue.use(VueRouter)

const routes = [{
    path:"/",
    redirect: "/wps",
    // component:() => import(/* webpackChunkName: "about" */ '@/views/entry.vue')
  },
  {
    name:"wps",
    path:"/wps",
    // redirect: "/projectstManage/projectManage",
    component:() => import(/* webpackChunkName: "about" */ '@/views/wps/index.vue')
  },
]

const router = new VueRouter({
  routes
})

export default router
