import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import axiosInstance from '@/untils/request.js';
import '@/untils/rem.js'
import '@/assets/ css/base.css'
//  引入Echarts
Vue.prototype.$request = axiosInstance;
Vue.config.productionTip = false
Vue.use(ElementUI);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
